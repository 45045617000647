<template>
  <div class="content">
    <el-card shadow="never" class="new-card">
      <div slot="header" class="card-header">
        <div class="card-header-left">
          <el-form
            :inline="true"
            size="small"
            :model="searchForm"
            class="demo-form-inline"
          >
            <el-form-item label="医生状态" prop="doctorEnable">
              <el-select
                placeholder="请选择医生状态"
                v-model="searchForm.doctorEnable"
              >
                <el-option
                  v-for="(item, index) in statOptions"
                  :label="item.label"
                  :value="item.value"
                  :key="index"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="是否绑定地区" prop="bindingStatus">
              <el-select
                placeholder="请选择"
                v-model="searchForm.bindingStatus"
              >
                <el-option label="绑定" :value="1" />
                <el-option label="未绑定" :value="2" />
                <el-option label="全部" :value="null" />
              </el-select>
            </el-form-item>
            <el-form-item label="更新方式" prop="updateType">
              <el-select placeholder="请选择" v-model="searchForm.updateType">
                <el-option label="手动" :value="1" />
                <el-option label="自动" :value="2" />
                <el-option label="全部" :value="null" />
              </el-select>
            </el-form-item>

            <el-form-item label="医生ID" prop="doctorId">
              <el-input
                placeholder="请输入"
                @change="getDoctorId"
                v-model="searchForm.doctorId"
              />
            </el-form-item>
            <el-form-item label="医生姓名" prop="doctorName">
              <el-input placeholder="请输入" v-model="searchForm.doctorName" />
            </el-form-item>
            <el-form-item label="部门编码" prop="deptCode">
              <el-input placeholder="请输入" v-model="searchForm.deptCode" />
            </el-form-item>
            <el-form-item label="部门名称" prop="deptName">
              <el-input placeholder="请输入" v-model="searchForm.deptName" />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSearch" size="small"
                >搜索</el-button
              >

              <el-upload
                style="display: inline"
                action
                :on-change="(file, fileList) => handleChange(file, fileList)"
                :auto-upload="false"
                :show-file-list="false"
                accept=".xls,.xlsx"
              >
                <el-button size="small" style="margin: 0 10px" type="primary"
                  >导入</el-button
                >
              </el-upload>
              <el-button type="primary" size="small" @click="downloadTemplate"
                >导出</el-button
              >
              <el-button type="primary" size="small" @click="onUpdateLink"
                >更新医生关联地区数据</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="card-header-left"></div>
      </div>
      <div :style="{ overflowY: 'auto', height: tableHeight }">
        <el-table
          size="small"
          :data="tableData"
          border
          height="calc(100% - 55px)"
        >
          <el-table-column label="部门编码" prop="deptCode" align="center" />
          <el-table-column label="部门名称" prop="deptName" align="center" />
          <el-table-column label="医生ID" prop="doctorId" align="center" />
          <el-table-column label="医生名称" prop="doctorName" align="center" />
          <el-table-column
            label="使用状态"
            prop="doctorEnableDesc"
            align="center"
          />
          <el-table-column label="最新操作人" prop="updateBy" align="center" />
          <el-table-column
            label="更新方式"
            prop="updateTypeDesc"
            align="center"
          />
          <el-table-column label="更新时间" prop="updateTime" align="center" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="handleEdit(scope.row)"
                >修改</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex; justify-content: flex-end; margin: 10px 0">
          <el-pagination
            :current-page="page.current"
            :page-sizes="page.sizes"
            :page-size="page.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-card>
    <el-dialog title="修改" :visible.sync="dialogVisible" width="70%">
      <el-table size="small" :data="editList" border>
        <el-table-column label="部门编码" align="center">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.deptCode"
              @blur="(e) => onBlurCode(e, scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="部门名称" align="center">
          <template slot-scope="scope">
            <el-select
              placeholder="请选择"
              size="small"
              filterable
              @change="(val) => onChangeDeptName(val, scope.row)"
              v-model="scope.row.deptName"
            >
              <el-option
                v-for="item in deptList"
                :key="item.deptCode"
                :label="item.deptName"
                :value="item.deptName"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="医生ID" prop="doctorId" align="center" />
        <el-table-column label="医生名称" prop="doctorName" align="center" />
        <el-table-column
          label="使用状态"
          prop="doctorEnableDesc"
          align="center"
        />
        <el-table-column label="更新方式" align="center">
          <template slot-scope="scope">
            <el-select
              placeholder="请选择"
              size="small"
              v-model="scope.row.updateType"
            >
              <el-option label="手动" :value="1" />
              <el-option label="自动" :value="2" />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      deptList: [],
      dialogVisible: false,
      editList: [],
      page: {
        current: 1, // 当前在第几页
        sizes: [10, 20, 50, 100], // 每页显示多少条选择数组
        size: 10, // 每页显示多少条
        total: 0, // 共几条
      },
      searchForm: {
        bindingStatus: null,
        doctorEnable: null,
        updateType: null,
        doctorId: null,
        doctorName: null,
        deptCode: null,
        deptName: null,
      },
      tableData: [], // 表格数据
      tableHeight: "850px",
      statOptions: [
        {
          value: 0,
          label: "停用",
        },
        {
          value: 1,
          label: "启用",
        },
        {
          value: null,
          label: "全部",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    // 获取高度
    this.tableHeight = document.body.clientHeight - 225 + "px";

    // 全选的数据
    this.oneDimensionalList = [];
  },
  methods: {
    getDoctorId(val) {
      const target = val.replace(/[^\d]/g, "");
      this.searchForm.doctorId = target ? Number(target) : null;
    },
    handleChange(file, fileList) {
      let formData = new FormData();
      formData.append("file", file.raw);
      console.log(formData);
      this.upload_file(formData);
    },
    upload_file(val) {
      let params = val;
      this.$store.dispatch("app/setLoading", true);
      console.log(params);
      this.axios
        .post("REGIONIMPORT", val)
        .then((result) => {
          console.log("查询返回", result);
          if (result.code === 0) {
            this.$message({
              message: "导入成功",
              type: "success",
            });
            this.getList();
          } else {
            const str = result.msg.replace(/(\n|\r|\r\n|↵)/g, "<br/>");
            this.$message({
              dangerouslyUseHTMLString: true,
              type: "error",
              message: str,
            });
          }
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    onChangeDeptName(val, row) {
      let filterableData = this.deptList.filter(
        (item) => item.deptName === val
      );
      row.deptName = val;
      row.deptCode = filterableData[0]?.deptCode;
    },
    onBlurCode(e, row) {
      let filterableData = this.deptList.filter(
        (item) => item.deptCode === row.deptCode
      );
      row.deptName = filterableData[0]?.deptName;
    },
    getDept() {
      this.axios.get("GETDEPTLIST", {}).then((result) => {
        console.log("result", result);
        if (result.code === 0) {
          this.deptList = result.data;
        }
      });
    },
    onConfirm() {
      console.log(this.editList);
      if (!this.editList[0]["deptCode"] || !this.editList[0]["deptName"]) {
        this.$message.warning("请填写完整数据");
        return;
      }
      this.axios
        .postQuery("REGIONUPDATE", {
          deptCode: this.editList[0]["deptCode"],
          updateType: this.editList[0]["updateType"],
          id: this.editList[0]["id"],
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.getList();
            this.dialogVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    onSearch() {
      this.page.current = 1;
      this.getList();
    },
    // 全量更新医生关联地区数据
    onUpdateLink() {
      this.$confirm("您确定要更新医生关联地区数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this._updateData();
      });
    },
    _updateData() {
      this.axios.post("REGIONDCOTORLINKUPDATE", "").then((res) => {
        if (res.code === 0) {
          this.$message({
            type: "success",
            message: "更新成功",
          });
          this.onSearch();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    handleDelete(row) {
      this.$confirm("您确定要删除该条数据吗？", "提示", {
        confirmButtonText: "确定",
        canceButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios.postQuery("REGIONDELETE", { id: row.id }).then((res) => {
          if (res.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    downloadTemplate() {
      this.axios.get("REGIONEXPORT", {}).then((result) => {
        console.log("result instanceof Blob", result);
        if (result instanceof Blob) {
          const blob = new Blob([result]);
          console.log(blob);
          const fileName = "地区关联表" + ".xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        } else {
          this.$message.error("导出失败！");
        }
      });
    },
    // 打开编辑
    handleEdit(items) {
      this.dialogVisible = true;
      this.editList = [Object.assign({}, items)];
      this.getDept();
    },
    // 获取列表
    getList() {
      this.axios
        .post("REGIONlIST", {
          bindingStatus: this.searchForm.bindingStatus,
          doctorEnable: this.searchForm.doctorEnable,
          updateType: this.searchForm.updateType,
          deptCode: this.searchForm.deptCode,
          deptName: this.searchForm.deptName,
          doctorId: this.searchForm.doctorId,
          doctorName: this.searchForm.doctorName,
          pageNum: this.page.current,
          pageSize: this.page.size,
        })
        .then((result) => {
          this.tableData = result.data.list;
          this.page.total = result.data.total;
        });
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
      this.getList();
    },
    // 切换到第几页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getList();
    },
  },
};
</script>

<style scoped>
.modular-box {
  display: flex;
  flex-direction: column;
}
.el-cascader__tags .el-tag {
  color: #909399;
}
.new-card .el-card__header {
  padding: 13px 20px;
}
.new-card .el-card__body {
  padding: 0 20px;
  /* background: #F0F2F5; */
}
.el-dialog__headerbtn .el-dialog__close {
  color: #1cd097;
}
.el-table::before {
  z-index: inherit;
}
.new-tag1 {
  border-radius: 4px;
  background: #2672ff10;
  color: #2672ff;
  border: 1px solid #2672ff50;
  padding: 4px 10px;
}
.new-tag2 {
  border-radius: 4px;
  background: #ffa50f10;
  color: #ffa50f;
  border: 1px solid #ffa50f50;
  padding: 4px 10px;
}
.new-tag3 {
  border-radius: 4px;
  background: #ff532210;
  color: #ff5322;
  border: 1px solid #ff532250;
  padding: 4px 10px;
}
.new-tag4 {
  border-radius: 4px;
  background: #9d4de610;
  color: #9d4de6;
  border: 1px solid #9d4de650;
  padding: 4px 10px;
}
.new-tag5 {
  border-radius: 4px;
  background: #1cd09710;
  color: #1cd097;
  border: 1px solid #1cd09750;
  padding: 4px 10px;
  width: 90px;
  margin: 0 auto;
}
</style>

<style lang="scss" scoped>
.content {
  overflow-y: auto;
  height: 90vh;
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      .top-title {
        font-size: 16px;
        color: #262626;
        font-weight: bold;
      }
      .question {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
      .question:hover {
        cursor: pointer;
      }
    }
    .card-header-right {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }
  .auth-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .auth-show-input {
    width: 300px;
  }
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
